import {
    Typography
} from '@mui/material';

const Error404 = () => {
    return (
        <div className='min-h-screen flex flex-col justify-center items-center p-3'>
            <Typography variant='h1'>404</Typography>
            <Typography variant='h5' sx={{ mb: 1, fontSize: '1.5rem !important' }}>
                Page Not Found ⚠️
            </Typography>
            <Typography variant='body2'>We couldn&prime;t find the page you are looking for.</Typography>
        </div>
    )
}

Error404.getLayout = page => <>{page}</>;
Error404.isPublic = true;

export default Error404;
